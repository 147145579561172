<template>
  <div class="footer-wrap" :class="{active: color}">
      <span><a href="https://beian.miit.gov.cn">@浙ICP备20011671号</a></span>
  </div>
</template>

<script>
export default {
    props:['color'],
}
</script>

<style lang="scss" scoped>
    @import '../assets/css/variables';
    .footer-wrap{
        color: map-get($colors, 'grey');
        margin-top: 5rem;
        margin-left: -25rem;
        background: map-get($colors, 'background' );
        text-align: center;
        height: 18rem;
        line-height: 15rem;
        &.active{
            background: #ddd;
        }
        a{
            text-decoration: none;
            color: #666;
        }
    }
</style>