<template>
  <div class="pb-15 mb-30">
      <div class="add-comment-wrap pb-10 mb-30 d-flex flex-column ai-end">
        <div class="d-flex flex-column ai-end">
          <textarea v-model="content" class="comment-text p-10 fs-15" name="" id="" maxlength="200" placeholder="请输入评论内容..."></textarea>
          <p>可输入字符长度： {{ maxLength }}</p>
        </div>
        <div class="publish-comment fs-15 m-c-p" @click="publishComment">发表评论</div>
      </div>
      <div class="">
        <p class="fs-15">全部评论 {{ articleInfo.count}}</p>
        <div v-if="!articleInfo.comment || articleInfo.comment.length == 0" class="d-flex flex-column ai-center p-30">
          <img style="width: 22rem" src="../assets/images/zanwu.png" alt="">
          <p class="p-30 fs-15" style="color: grey">暂无评论</p>
        </div>

        <!-- 首级评论 -->
        <div v-else>
          <div v-for="(item, index) in articleInfo.comment" :key="item._id" class="p-30 d-flex flex-column" style="border-bottom: 1px solid #eee; transition: all 0.5s ease-in-out">
            <div class="d-flex">
              <div class="touxiang-wrap mr-30" style="flex-shrink: 0">
                <img style="width: 100%" :src="item.commentUserId ? item.commentUserId.touxiang  : 'http://www.sunwenliang.com/upload/touxiang1.png'" alt="">
              </div>
              <div class="comment-content d-flex jc-between flex-grow-1 ai-end">
                <div class="d-flex flex-column pr-30">
                  <div class="d-flex ai-center">
                    <span class="fs-18 mt-5"><strong>{{ item.commentUserId ? item.commentUserId.username : "游客"}}</strong></span>
                    <span class="mt-5 ml-20" style="color: #999"> {{ item.duringDate }}</span>
                  </div>
                  <span class="fs-15 mt-10" style="color: #666; line-height: 2.5rem">{{ item.commentContent }}</span>
                </div>
                <div class="d-flex" style="flex-shrink: 0">
                  <span v-if="item.commentUserId && $store.state.user._id == item.commentUserId._id" class="m-c-p mt-2 mr-15 remove-comment" @click="removeComment(item._id, index)">删除</span>
                  <span class="m-c-p iconfont icon-pinglun fs-20 mr-20 mt-2" @click="openInput(item)"></span>
                  <span class="m-c-p iconfont icon-dianzan_active fs-20 mr-8" :class="{ dianzan_active: item.like.indexOf($store.state.user._id) > -1 }" @click="dianzanPinglun(item, $event)"></span>
                  <span class="m-c-p fs-15 mt-3" style="color: grey">{{ item.like ? item.like.length : 0 }}</span>
                </div>
              </div>

            </div>
            <div class="d-flex" style="margin-left: 9rem; margin-top: 2rem" v-if="item.reply">
              <input type="text" :placeholder="`回复 ${ item.commentUserId ? item.commentUserId.username : '游客'}：`" v-model="pinglun.content" class="reply-content flex-grow-1 mr-20">
              <button class="reply-button fs-15 m-c-p" @click="reply2pinglun(item._id, item.commentUserName || item.commentUserId._id)">回复</button>
            </div>

            <!-- 评论中的回复 -->
            <div v-if="item.comment.length != 0" style="margin-left: 9rem; margin-top: 2rem; background: #f9f9f9">
              <div v-for="(reply, i) in item.comment" :key="reply._id" class="d-flex flex-column pb-5" style="border-bottom: 1px solid #eee">
                <div class="d-flex">
                  <!-- 头像 -->
                  <div style="width: 5rem; height: 5rem; border-radius: 5rem; overflow:hidden;" class="m-15">
                    <img style="width:100%" :src="reply.responseUserId ? reply.responseUserId.touxiang : 'http://www.sunwenliang.com/upload/touxiang1.png'" alt="">
                  </div>
                  <!-- 回复内容 -->
                  <div class="d-flex jc-between flex-grow-1 ai-end pb-10">
                    <div class="d-flex flex-column mt-10">
                      <div class="d-flex ai-center">
                        <span class="fs-18"><strong>{{ reply.responseUserId ? reply.responseUserId.username : '游客' }}</strong></span>
                        <span class="mt-5 ml-20" style="color: #999"> {{ reply.duringDate }}</span>
                      </div>
                      <span class="fs-15 mt-5" style="color: #666; line-height: 2.5rem">回复 <strong>{{ reply.responseWhoId ? reply.responseWhoId.username : '游客' }}</strong> : {{ reply.responseContent }}</span>
                    </div>
                    <div class="d-flex mr-30" style="flex-shrink: 0">
                      <span v-if="reply.responseUserId && $store.state.user._id == reply.responseUserId._id" class="m-c-p mt-2 mr-15 remove-comment" @click="removeReply(item._id, reply._id, index, i)">删除</span>
                      <span class="m-c-p iconfont icon-pinglun fs-20 mr-20 mt-2" @click="openInput(reply)"></span>
                      <span class="m-c-p iconfont icon-dianzan_active fs-20 mr-8" :class="{ dianzan_active: reply.like.indexOf($store.state.user._id) > -1 }" @click="dianzanReply(item, reply, i, $event)"></span>
                      <span class="m-c-p fs-15 mt-3" style="color: grey">{{ reply.like ? reply.like.length : 0 }}</span>
                    </div>
                  </div>
                </div>
                <!-- 再次回复输入框 -->
                <div class="d-flex mb-10 mr-30" style="margin-left: 8rem; margin-top: 2rem" v-if="reply.reply">
                  <input type="text" :placeholder="`回复 ${reply.responseUserId ? reply.responseUserId.username : '游客'}：`" v-model="pinglun.content" class="reply-content flex-grow-1 mr-20">
                  <button class="reply-button fs-15 m-c-p" @click="reply2pinglun(item._id,  reply.responseUserId ? reply.responseUserId._id : '游客')">回复</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props:['articleInfos'],
  inject:['reload'],
  data(){
    return {
      content:'',
      articleInfo: {},
      pinglun: {
        content:''
      },

    }
  },
  methods:{
    async publishComment(){
      if(!this.content){
        this.$message({
          message:"请先输入内容！"
        })
      }else{
        let comment = {
          commentContent: this.content,
          commentUserId: this.$store.state.user._id ? this.$store.state.user._id : '',
        }
        console.log(comment)
        comment.commentUserId || (delete comment.commentUserId)
        comment.commentUserId || (comment.commentUserName = '游客')
        const result = await this.$axios.post(`/publishComment/${this.articleInfo._id}`, comment)
        this.articleInfo = result.data
        this.content = ''
        this.articleInfo.count && this.articleInfo.count ++
        this.$message({message: "发布成功"})
      }
    },
    duringTime(publishTime){
      let newDate = new Date().getTime()
      let duringT = newDate - publishTime
      if(duringT < 1000 * 60 * 60){
        if(duringT < 1000 * 60){
          return `${parseInt(duringT/1000)}秒前`
        }
        return `${parseInt(duringT/(1000 * 60))}分钟前`
      }else if(duringT < 1000 * 60 * 60 * 24){
        return `${parseInt(duringT/(1000 * 60 * 60))}小时前`
      }else{
        return `${parseInt(duringT/(1000 * 60 * 60 * 24))}天前`
      }
    },
    async removeComment(param, index){
      const result = await this.$axios.delete(`/removeComment/${this.articleInfo._id}/${param}`)
      this.articleInfo.comment.splice(index, 1)
      this.$message({message: "删除成功"})
    },
    async reply2pinglun(pinglunId, who){
      if(!this.pinglun.content){return this.$message({message: '请输入内容'})}
      const comment = {
        responseUserId: this.$store.state.user._id ? this.$store.state.user._id : null,
        responseUserName: this.$store.state.user.username ? this.$store.state.user.username : '游客',
        responseContent: this.pinglun.content
      }
      comment.responseUserId || (delete comment.responseUserId)
      who == "游客" ? comment.responseWhoName = "游客" : comment.responseWhoId = who
      const result = await this.$axios.post(`/reply2pinglun/${pinglunId}`, comment)
      this.reload()
      this.$message({message:'回复成功'})
    },
    openInput(currentEle){
      let currentStatus = currentEle.reply
      this.articleInfo.comment.map(item => {
        this.$set(item, 'reply', false)
        item.comment.map(ele => this.$set(ele, 'reply', false))
      })
      this.$set(currentEle, 'reply', !currentStatus)
    },
    async removeReply(commentId, replyId, commentIndex, replyIndex){
      const result = await this.$axios.delete(`/removeReply/${commentId}/${replyId}`)
      if(result.data.message == 'success'){
        this.articleInfo.comment[commentIndex].comment.splice(replyIndex, 1)
      }
    },
    async dianzanPinglun(item, e){
      if(!localStorage.token){return this.$message({type: 'warning', message: '请先登录！'})}
      if(Array.from(e.target.classList).indexOf('dianzan_active') > -1){
        await this.$axios.get(`/dzcomment/remove/${item._id}/${this.$store.state.user._id}`)
        item.like.splice(item.like.indexOf(this.$store.state.user._id), 1)
      }else{
        await this.$axios.get(`/dzcomment/add/${item._id}/${this.$store.state.user._id}`)
        item.like.push(this.$store.state.user._id)
      }
    },
    async dianzanReply(item, reply, i, e){
      if(!localStorage.token){return this.$message({type: 'warning', message: '请先登录！'})}
      if(Array.from(e.target.classList).indexOf('dianzan_active') > -1){
        await this.$axios.get(`/dzreply/remove/${item._id}/${i}/${this.$store.state.user._id}`)
        reply.like.splice(reply.like.indexOf(this.$store.state.user._id), 1)
      }else{
        await this.$axios.get(`/dzreply/add/${item._id}/${i}/${this.$store.state.user._id}`)
        reply.like.push(this.$store.state.user._id)
      }
    }
  },
  computed:{
    maxLength(){
      return 200 - this.content.length
    }
  },
  watch:{
    articleInfos(value){
      value.count = 0
      if(value.comment){
        for(let i = 0; i < value.comment.length; i ++){
          value.comment[i].duringDate = this.duringTime(value.comment[i].publishTime)
          value.count ++
          if(value.comment[i].comment){
            for(let j = 0; j < value.comment[i].comment.length; j ++){
              value.comment[i].comment[j].duringDate = this.duringTime(value.comment[i].comment[j].replyTime)
              value.count ++
            }
          }
        }
      }
      this.articleInfo = value
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
  .add-comment-wrap{
    border-bottom: 1px solid map-get($colors, 'border-light');
    .comment-text{
      width: 138rem;
      height: 7rem;
      border: 1px solid map-get($colors, 'grey');
      border-radius: 0.5rem;
      resize: none;
    }
    p{
      color: map-get($colors, 'grey');
      transform: translate(-2rem, -3.5rem);
    }
    .publish-comment{
      width: 12rem;
      height: 4rem;
      background: map-get($colors, 'purple');
      border-radius: 0.5rem;
      color: white;
      line-height: 4rem;
      text-align: center;
      transform: translate(0, -1.5rem);
    }
  }
  .touxiang-wrap{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .remove-comment{
    color: map-get($colors, 'grey');
  }
  .remove-comment:hover{
    color: #c22121;
  }
  .iconfont{
    color: #bbb;
  }
  .icon-pinglun:hover{
    color: map-get($colors, 'purple');
  }
  .icon-dianzan_active:hover{
    color: map-get($colors, 'purple');
  }
  .icon-dianzan_active.dianzan_active{
    color: map-get($colors, 'purple');
  }
  .reply-content{
    padding: 1rem;
    height: 4rem;
    border:1px solid map-get($colors, 'border-light' );
    border-radius: 0.5rem;
  }
  .reply-button{
    background: map-get($colors, 'purple');
    color: white;
    width: 8rem;
    border-radius: 0.5rem;
    border: 1px solid map-get($colors, 'border-light' );
  }
</style>